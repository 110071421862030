import * as React from "react"


const NotFoundPage = () => (
  <main>
    404
  </main>
);


export default NotFoundPage
